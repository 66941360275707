import React, { useState } from "react"
import GitexPopupModal from "../../core/GitexPopupModal"

export const GitexBtn = () => {
  const [openGitex, setOpenGitex] = useState(false)
  const openGitexModal = () => {
    setOpenGitex(true)
  }
  const closeGitexModal = () => {
    setOpenGitex(false)
  }
  return (
    <>
      {openGitex && (
        <GitexPopupModal
          open={openGitex}
          closeGitexModal={closeGitexModal}
          url={
            "https://meetings.hubspot.com/sales-guy?uuid=b3da84be-73f9-470d-baf6-b9d6f76eb811"
          }
        />
      )}
      <div className="flex justify-center w-full">
        <button
          className="mt-10 md:my-8 lg:my-3 bg-blue-500 relative px-8 py-3 text-base leading-6 font-medium text-white  hover:bg-blue-600 rounded"
          onClick={openGitexModal} //remove after gitex2022
        >
          Let’s Have Coffee Together
        </button>
      </div>
    </>
  )
}
export default GitexBtn